import React, { useEffect, useState } from "react";
import { Card, Table, Form, Button, Col } from "react-bootstrap";
import "./AllAlbumsTable.css";
import { FaCircle, FaPencil, FaTrash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const AllAlbumsTable = () => {
  // const albums = [
  //   {
  //     id: "001",
  //     title: "Echoes of Silence",
  //     releaseDate: "10 January 2021",
  //     genre: "Alternative",
  //     status: "Released",
  //     statusClass: "success",
  //   },
  //   {
  //     id: "002",
  //     title: "Celestial Harmony",
  //     releaseDate: "24 March 2022",
  //     genre: "Ambient",
  //     status: "Upcoming",
  //     statusClass: "warning",
  //   },
  //   {
  //     id: "003",
  //     title: "Electric Dreams",
  //     releaseDate: "15 August 2020",
  //     genre: "Synthwave",
  //     status: "Released",
  //     statusClass: "success",
  //   },
  //   {
  //     id: "004",
  //     title: "Mystic River",
  //     releaseDate: "05 May 2019",
  //     genre: "Folk",
  //     status: "Released",
  //     statusClass: "success",
  //   },
  //   {
  //     id: "005",
  //     title: "Future Nostalgia",
  //     releaseDate: "18 November 2023",
  //     genre: "Pop",
  //     status: "Upcoming",
  //     statusClass: "warning",
  //   },
  // ];

  const navigate = useNavigate();
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        // Fetch artistId from localStorage
        const artistProfile = JSON.parse(localStorage.getItem("profile"));
        const artistId = artistProfile?.artistId;

        if (!artistId) {
          setError("Artist ID not found");
          return;
        }

        // Call the API to fetch albums by the artist
        const response = await axios.get(
          `https://fttunes.com/api/artists/${artistId}/albums`
        );
        setAlbums(response.data.data); // Assuming API returns albums in `data.data`
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch albums");
        setLoading(false);
      }
    };

    fetchAlbums();
  }, []);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>{error}</div>;
  // }

  const handleDeleteAlbum = async (id) => {
    if (window.confirm("Are you sure you want to delete this album?")) {
      try {
        await axios.delete(`https://fttunes.com/api/albums/${id}`);
        setAlbums(albums.filter((album) => album.id !== id));
      } catch (error) {
        console.error("Error deleting album:", error);
      }
    }
  };

  return (
    <Col className="artists-table">
      <Card>
        <Card.Header>
          {/* <Button
                  variant="primary"
                  className="theme-btn add_artist_btn"
                  // style={{ margin: "20px 10px" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button> */}
          <h4 className="card-title">
            <mark>My Releases</mark>
          </h4>
          <Button
            as={Link}
            to="/artist/releases/create"
            className="theme-btn add_album_btn"
          >
            Add New Release
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table responsive="md" variant="dark">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>
                    <Form.Check
                      type="checkbox"
                      id="checkAllAlbums"
                      className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                      style={{ border: "none" }}
                      label=""
                    />
                  </th>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Release Date</th>
                  <th>Genre</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {albums.length > 0 ? (
                  albums.map((album, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          id={`customCheckBoxAlbum${index + 2}`}
                          className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                          label=""
                          style={{ border: "none" }}
                        />
                      </td>
                      <td>
                        <strong>{album.id}</strong>
                      </td>
                      <td>
                        <Link
                          to={`/artist/album/${album.id}`}
                          style={{ textDecoration: "", color: "#fff" }}
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={`https://fttunes.com/api/${album.uploadPoster}`}
                              className="rounded-lg me-2"
                              width="24"
                              alt=""
                            />
                            <span className="w-space-no">{album.songName}</span>
                          </div>
                        </Link>
                      </td>
                      <td>
                        {new Date(album.liveDate).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </td>
                      <td>{album.primaryGenre}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <FaCircle
                            className={`text-${
                              album.status === "Released"
                                ? "success"
                                : album.status === "Rejected"
                                ? "danger"
                                : album.status === "Approved"
                                ? "approved"
                                : "warning"
                            } me-1`}
                          />
                          {album.status}
                        </div>
                      </td>

                      <td>
                        {album.status === "Pending" && (
                          <div className="d-flex">
                            <Button
                              variant="primary"
                              size="sm"
                              className="me-1"
                              style={{ padding: "0.625rem 1rem" }}
                              onClick={() =>
                                navigate(`/artist/releases/edit/${album.id}`)
                              }
                            >
                              <FaPencil />
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              style={{ padding: "0.625rem 1rem" }}
                              onClick={() => handleDeleteAlbum(album.id)}
                            >
                              <FaTrash />
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No albums to show.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default AllAlbumsTable;
