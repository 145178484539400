import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Tabs,
  Tab,
  Card,
  Image,
} from "react-bootstrap";
import "./Profile2.css";
import { FaEdit, FaInfoCircle } from "react-icons/fa";
import { FaEye, FaEyeSlash, FaUser } from "react-icons/fa6";
import { MdAccountBalance, MdLogin, MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import { use } from "react";

const Profile2 = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  const [updateprofile, setupdateprofile] = useState(false);
  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("profile")));
    console.log("profile", profile);
    const fetchprofile = async () => {
      try {
        const response = await axios.get(
          `https://fttunes.com/api/artists/${profile.artistId}`
        );
        localStorage.setItem("profile", JSON.stringify(response.data));
        // console.log("response", response);
        // setProfile(response.data); // Initialize form data with artist profile from API
      } catch (error) {
        console.error("Error fetching artist data:", error);
      }
    };
    fetchprofile();
  }, [updateprofile]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    email: "",
    phone: "",
    address: "",
    maritalStatus: "",
    description: "",
    websiteUrl: "",
    facebookUrl: "",
    twitterUrl: "",
    linkedInUrl: "",
    spotifyUrl: "",
    wynkUrl: "",
    gaanaUrl: "",
    instagramUrl: "",
    bankAccountName: "",
    bankAccountNumber: "",
    bankName: "",
    ifscCode: "",
    swiftCode: "",
    anniversaryDate: "",
    pincode: "",
    state: "",
  });

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const [showModal2, setShowModal2] = useState(false);
  const handleCloseModal2 = () => setShowModal2(false);
  const handleShowModal2 = () => {
    // setModalType(type);
    setShowModal2(true);
  };

  const [showModal3, setShowModal3] = useState(false);
  const handleCloseModal3 = () => setShowModal3(false);
  const handleShowModal3 = () => {
    // setModalType(type);
    setShowModal3(true);
  };

  const [showModal4, setShowModal4] = useState(false);
  const handleCloseModal4 = () => setShowModal4(false);
  const handleShowModal4 = () => {
    // setModalType(type);
    setShowModal4(true);
  };

  const [showModal5, setShowModal5] = useState(false);
  const handleCloseModal5 = () => setShowModal5(false);
  const handleShowModal5 = () => {
    // setModalType(type);
    setShowModal5(true);
  };

  useEffect(() => {
    if (profile) {
      setFormData({
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        gender: profile.gender || "",
        dob: profile.dob || "",
        email: profile.email || "",
        address: profile.address || "",
        phone: profile.phone || "",
        maritalStatus: profile.maritalStatus || "",
        description: profile.description || "",
        websiteUrl: profile.websiteUrl || "",
        facebookUrl: profile.facebookUrl || "",
        twitterUrl: profile.twitterUrl || "",
        linkedInUrl: profile.linkedInUrl || "",
        spotifyUrl: profile.spotifyUrl || "",
        wynkUrl: profile.wynkUrl || "",
        gaanaUrl: profile.gaanaUrl || "",
        instagramUrl: profile.instagramUrl || "",
        bankAccountName: profile.bankAccountName || "",
        bankAccountNumber: profile.bankAccountNumber || "",
        bankName: profile.bankName || "",
        ifscCode: profile.ifscCode || "",
        swiftCode: profile.swiftCode || "",
        anniversaryDate: profile.anniversaryDate || "",
        pincode: profile.pincode || "",
        state: profile.state || "",
      });
    }
  }, [profile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSubmit = () => {
  //   handleCloseModal();
  // };

  const handleSubmit = async () => {
    // Here you would typically send the form data to the backend to update the profile
    console.log("Form submitted with data: ", formData);
    try {
      console.log("formdata", formData);
      const response = await axios.put(
        `https://fttunes.com/api/artists/${profile.artistId}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("response", response.data);
      alert("Artist data updated successfully!");
      // navigate("/artists");
    } catch (error) {
      console.error("Error updating artist data:", error);
      alert("Failed to update artist data.");
      // navigate("/artists");
    }
    handleCloseModal();
    handleCloseModal2();
    handleCloseModal3();
    handleCloseModal4();
    handleCloseModal5();
  };

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyNewPassword, setVerifyNewPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);
      setPasswordMatch(value === verifyNewPassword);
    } else if (name === "verifyNewPassword") {
      setVerifyNewPassword(value);
      setPasswordMatch(value === newPassword);
    }
  };

  // const handleotpSubmit = (e) => {
  //   // e.preventDefault();
  //   if (passwordMatch) {
  // Handle password update logic here
  // console.log("Password updated successfully");
  // handleCloseModal();
  const handleotpSubmit = async (e) => {
    e.preventDefault();

    if (!passwordMatch) {
      alert("New passwords do not match!");
      return;
    }

    if (!oldPassword || !newPassword) {
      alert("Please fill in all password fields.");
      return;
    }

    try {
      const response = await axios.put(
        `https://fttunes.com/api/artists/${profile.artistId}/change-password`, // Your backend endpoint
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.success) {
        alert("Password changed successfully!");
      } else {
        alert("Failed to change password: " + response.data.message);
      }
    } catch (error) {
      console.error("Error changing password:", error);
      alert("Error changing password. Please try again.");
    }

    // Clear the form fields
    setOldPassword("");
    setNewPassword("");
    setVerifyNewPassword("");

    // Close the modal
    handleCloseModal();
    handleCloseModal2();
    handleCloseModal3();
    handleCloseModal4();
    handleCloseModal5();
  };

  // }
  // };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formDataImage = new FormData();
      formDataImage.append("profileImage", file);

      try {
        const response = await axios.put(
          `https://fttunes.com/api/artists/${profile.artistId}`,
          formDataImage,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log("Image upload response:", response.data);
        alert("Profile image updated successfully!");
        setupdateprofile((prev) => !prev);
        // Optionally update the local storage with the new profile image data
        // const updatedProfile = {
        //   ...profile,
        //   profileImage: response.data.profileImage,
        // };
        // localStorage.setItem("profile", JSON.stringify(updatedProfile));
        // setProfile(updatedProfile);
      } catch (error) {
        console.error("Error uploading profile image:", error);
        alert("Failed to upload profile image.");
      }
    }
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [payments, setPayments] = useState([]);
  useEffect(() => {
    const fetchpayments = async () => {
      try {
        const response = await axios.get(
          `https://fttunes.com/api/payments/${profile.artistId}`
        );
        console.log("response for payments", response.data);
        setPayments(response.data.payments);
        // console.log("response", response);
        // setProfile(response.data); // Initialize form data with artist profile from API
      } catch (error) {
        console.error("Error fetching payments data:", error);
      }
    };
    fetchpayments();
  }, []);

  const generatePDF = (payment) => {
    const container = document.createElement("div");
    document.body.appendChild(container);

    const root = createRoot(container);
    root.render(<InvoiceTemplate payment={payment} />);

    // Use a timeout to ensure rendering is complete
    setTimeout(() => {
      const ticketDataElement = container.querySelector(".invoicedata");

      if (ticketDataElement) {
        html2canvas(ticketDataElement, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL("");
          const doc = new jsPDF("p", "mm", "a4");
          const componentWidth = doc.internal.pageSize.getWidth();
          const componentHeight = doc.internal.pageSize.getHeight();
          const imageWidth = canvas.width;
          const imageHeight = canvas.height;
          const ratio = Math.min(
            componentWidth / imageWidth,
            componentHeight / imageHeight
          );
          const imageX = componentWidth - imageWidth * ratio;
          const imageY = 10;
          doc.addImage(
            imgData,
            "PNG",
            imageX,
            imageY,
            imageWidth * ratio,
            imageHeight * ratio
          );
          doc.save(`Invoice_${payment.id}.pdf`);

          // Clean up the container and unmount the component
          root.unmount();
          document.body.removeChild(container);
        });
      } else {
        console.error("Element with class 'ticketdata' not found");
        root.unmount();
        document.body.removeChild(container);
      }
    }, 100); // Adjust the timeout duration if necessary
  };

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const handleCloseDetailsModal = () => setShowDetailsModal(false);
  const handleShowDetailsModal = (payment) => {
    setSelectedPayment(payment); // Set the payment data
    setShowDetailsModal(true); // Show the modal
  };

  const [albums, setAlbums] = useState([]);
  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        if (!profile.artistId) {
          return;
        }
        const response = await axios.get(
          `https://fttunes.com/api/artists/${profile.artistId}/albums`
        );
        console.log("albums", response.data.data);
        setAlbums(response.data.data); // Assuming API returns albums in `data.data`
      } catch (err) {}
    };

    fetchAlbums();
  }, []);

  console.log("formdata", formData);
  return (
    <div
      className="profile_artist"
      style={{ paddingBlock: "60px", minHeight: "100vh" }}
    >
      <Container>
        <Tabs
          defaultActiveKey="Account Settings"
          id="fill-tab-example"
          className="mb-3"
          // fill
        >
          <Tab eventKey="Account Settings" title="Account Settings">
            <Container className="contentWrapper contentWrapper--myProfile">
              <Row>
                <Col
                  md={3}
                  className="leftSecWrapper"
                  // style={{
                  //   position: "sticky",
                  //   top: "100px",
                  //   left: "0px",
                  //   height: "fit-content",
                  // }}
                >
                  <Row>
                    <Col className="profilSdeBar profilSdeBar--sticky">
                      <div
                        className="proflPicContanr"
                        style={{
                          backgroundImage: `url(${
                            profile.profileImage
                              ? `https://fttunes.com/api/${profile.profileImage}`
                              : "/Images/cartoon.png"
                          })`,
                        }}
                      >
                        <div className="proflPicContanr__nmeIniContanr">
                          {/* <span className="latoBlack whiteText proflPicContanr__nmeIni">
                      {formData.firstName[0]}
                    </span> */}
                        </div>
                        <span className="myPrfilSprit myPrfilSprit__editGreyBg proflPicContanr__editGreyBgIcn">
                          <input
                            type="file"
                            name="profileImage"
                            accept=".jpg,.jpeg,.bmp,.gif,.png,.PNG,.JPG,.JPEG,.BMP,.GIF"
                            className="proflPicContanr__inputUpload"
                            onChange={handleImageUpload}
                          />
                        </span>
                      </div>

                      <div className="profilSdeBar__contntWrap">
                        <p className="font20 latoBlack blackText">
                          {profile.firstName}
                        </p>
                        <p className="font12 darkGreyText appendTop10">
                          PERSONAL PROFILE
                        </p>
                      </div>

                      <ListGroup className="sdeBarNav">
                        <ListGroup.Item
                          className="font14 darkGreyText latoBold sdeBarNav__navItm sdeBarNav__navItm-active"
                          onClick={() => scrollToSection("profile")}
                        >
                          <span className="sdeBarNav__iconContnr">
                            <FaUser />
                          </span>
                          Profile
                        </ListGroup.Item>

                        <ListGroup.Item
                          className="font14 darkGreyText latoBold sdeBarNav__navItm"
                          onClick={() => scrollToSection("login")}
                        >
                          <span className="sdeBarNav__iconContnr">
                            <MdLogin />
                          </span>
                          Login Details
                        </ListGroup.Item>

                        <ListGroup.Item
                          className="font14 darkGreyText latoBold sdeBarNav__navItm"
                          onClick={() => scrollToSection("accountdetails")}
                        >
                          <span className="sdeBarNav__iconContnr">
                            <MdAccountBalance />
                          </span>
                          Account Details
                        </ListGroup.Item>

                        <ListGroup.Item
                          className="font14 darkGreyText latoBold sdeBarNav__navItm"
                          onClick={() => navigate("/")}
                        >
                          <span className="sdeBarNav__iconContnr">
                            <MdLogout />
                          </span>
                          Logout
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                </Col>
                {/* <Col md={1}></Col> */}
                <Col md={9}>
                  <Container
                    id="profile"
                    className="profilCompont cm__myTripSideBar cm__myProfileTheme"
                  >
                    <Row className="detalCrdHadr">
                      <Col className="appendRight10">
                        <h3 className="font30 latoBold blackText">Profile</h3>
                        <p className="font18 darkGreyText appendTop10">
                          Basic info, for a faster booking experience
                        </p>
                      </Col>
                      <Col
                        className="text-right"
                        style={{ textAlign: "right" }}
                      >
                        <Button
                          className="font16 latoBold blueText btn__dtailAdEdt"
                          onClick={() => handleShowModal()}
                        >
                          <FaEdit style={{ marginRight: "10px" }} />
                          Edit
                        </Button>
                      </Col>
                    </Row>

                    <ListGroup className="profilCompont__dtalLst">
                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey">
                            NAME
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {formData["firstName"]}
                          </span>
                          {!formData["firstName"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey">
                            BIRTHDAY
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {formData["dob"]}
                          </span>
                          {!formData["dob"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey">
                            GENDER
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {formData["gender"]}
                          </span>
                          {!formData["gender"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey">
                            MARITAL STATUS
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {formData["maritalStatus"]}
                          </span>
                          {!formData["maritalStatus"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey">
                            YOUR ADDRESS
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {formData["address"]}
                          </span>
                          {!formData["address"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey">
                            PINCODE
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {formData["pincode"]}
                          </span>
                          {!formData["pincode"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey">
                            STATE
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {formData["state"]}
                          </span>
                          {!formData["state"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      {formData.maritalStatus === "Married" && (
                        <ListGroup.Item className="profilCompont__dtalLstItm">
                          <div className="profilCompont__dtalLstItmContnt">
                            <span className="font11 darkGreyText profilCompont__detalkey">
                              Anniversary
                            </span>
                            <span className="font16 blackText latoBold appendRight5">
                              {/* {new Date(
                              formData["anniversaryDate"]
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })} */}
                              {formData["anniversaryDate"]}
                            </span>
                            {!formData["anniversaryDate"] && (
                              <span
                                className="font14 latoBold blueText appendRight30 cursorPointer"
                                onClick={() => handleShowModal()}
                              >
                                + Add
                              </span>
                            )}
                          </div>
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </Container>

                  <Container
                    id="login"
                    className="lognDtalComp cm__myTripSideBar cm__myProfileTheme"
                  >
                    <div className="detalCrdHadr">
                      <div className="appendRight10">
                        <h3 className="font30 latoBold blackText">
                          Login Details
                        </h3>
                        <p className="font18 darkGreyText appendTop10">
                          Manage your mobile number, email address, and password
                        </p>
                      </div>
                    </div>
                    <ListGroup className="lognDtalComp__dtalLst">
                      <ListGroup.Item className="lognDtalComp__dtalLstItm">
                        <div className="lognDtalComp__dtalLstItmContnt">
                          <span className="font11 darkGreyText lognDtalComp__detalkey">
                            MOBILE NUMBER
                          </span>
                          <span className="font16 blackText latoBold">
                            &lrm;{formData["phone"]}
                          </span>
                          {!formData["phone"] && (
                            <span className="font14 latoBold  blueText makeFlex hrtlCenter noShrink appendLeft10">
                              <span className="myPrfilSprit myPrfilSprit__verified appendRight5"></span>
                              {/* Verified */}+ Add
                            </span>
                          )}
                          <div className="lognDtalComp__editDeltContanr">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  <p className="font14 latoMedium blackText">
                                    Are you sure you want to remove this mobile
                                    number?
                                  </p>
                                  <div className="font12 latoBold appendTop10 textCenter">
                                    <span
                                      className="appendRight30 appendLeft30 cursorPointer"
                                      role="button"
                                    >
                                      Cancel
                                    </span>
                                    <span className="redText cursorPointer">
                                      Yes, Remove
                                    </span>
                                  </div>
                                </Tooltip>
                              }
                            >
                              <div
                                className="appendLeft15 lognDtalComp__dltBtn"
                                role="button"
                              >
                                <span className="myPrfilSprit myPrfilSprit__delete cursorPointer"></span>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="lognDtalComp__dtalLstItm">
                        <div className="lognDtalComp__dtalLstItmContnt">
                          <span className="font11 darkGreyText lognDtalComp__detalkey">
                            EMAIL ID
                          </span>
                          <span className="font16 blackText latoBold">
                            {formData["email"]} &lrm;
                          </span>
                          {!formData["email"] && (
                            <span
                              className="font14 latoBold primaryText appendRight30 cursorPointer"
                              onClick={() => handleShowModal2()}
                            >
                              + Add your Email ID
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="lognDtalComp__dtalLstItm">
                        <div className="lognDtalComp__dtalLstItmContnt">
                          <span className="font11 darkGreyText lognDtalComp__detalkey">
                            PASSWORD
                          </span>
                          <span className="blacktext latoBold lognDtalComp__pswrdTxt">
                            ......
                          </span>
                          <span
                            className="font14 latoBold primaryText appendRight30 cursorPointer pushRight"
                            onClick={() => handleShowModal4()}
                          >
                            Change Password?
                          </span>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Container>

                  <Container
                    className="profilCompont cm__myTripSideBar cm__myProfileTheme"
                    id="accountdetails"
                  >
                    <Row className="detalCrdHadr">
                      <Col className="appendRight10">
                        <h3 className="font30 latoBold blackText">
                          Account Details
                        </h3>
                        <p className="font18 darkGreyText appendTop10">
                          Basic info, for a faster booking experience
                        </p>
                      </Col>
                      <Col
                        className="text-right"
                        style={{ textAlign: "right" }}
                      >
                        <Button
                          className="font16 latoBold blueText btn__dtailAdEdt"
                          onClick={() => handleShowModal5()}
                        >
                          <FaEdit style={{ marginRight: "10px" }} />
                          Edit
                        </Button>
                      </Col>
                    </Row>

                    <ListGroup className="profilCompont__dtalLst">
                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey2">
                            BANK ACCOUNT NAME
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {" "}
                            {formData["bankAccountName"]}
                          </span>
                          {!formData["bankAccountName"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal5()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey2">
                            BANK ACCOUNT NUMBER
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {" "}
                            {formData["bankAccountNumber"]}
                          </span>
                          {!formData["bankAccountNumber"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal5()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey2">
                            BANK NAME
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {" "}
                            {formData["bankName"]}
                          </span>
                          {!formData["bankName"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal5()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey2">
                            IFSC CODE
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {" "}
                            {formData["ifscCode"]}
                          </span>
                          {!formData["ifscCode"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal5()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="profilCompont__dtalLstItm">
                        <div className="profilCompont__dtalLstItmContnt">
                          <span className="font11 darkGreyText profilCompont__detalkey2">
                            SWIFT CODE
                          </span>
                          <span className="font16 blackText latoBold appendRight5">
                            {formData["swiftCode"]}
                          </span>
                          {!formData["swiftCode"] && (
                            <span
                              className="font14 latoBold blueText appendRight30 cursorPointer"
                              onClick={() => handleShowModal5()}
                            >
                              + Add
                            </span>
                          )}
                        </div>
                      </ListGroup.Item>

                      {/* <ListGroup.Item className="profilCompont__dtalLstItm">
                  <div className="profilCompont__dtalLstItmContnt">
                    <span className="font11 darkGreyText profilCompont__detalkey">
                      PINCODE
                    </span>
                    <span className="font16 blackText latoBold appendRight5"></span>
                    <span
                      className="font14 latoBold blueText appendRight30 cursorPointer"
                    >
                      + Add
                    </span>
                  </div>
                </ListGroup.Item>

                <ListGroup.Item className="profilCompont__dtalLstItm">
                  <div className="profilCompont__dtalLstItmContnt">
                    <span className="font11 darkGreyText profilCompont__detalkey">
                      STATE
                    </span>
                    <span className="font16 blackText latoBold appendRight5"></span>
                    <span
                      className="font14 latoBold blueText appendRight30 cursorPointer"
                    >
                      + Add
                    </span>
                  </div>
                </ListGroup.Item> */}
                    </ListGroup>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Tab>
          <Tab
            eventKey="Subscriptions"
            title="Subscriptions"
            className="subscription-tab"
          >
            <Container>
              <section
                id="subscriptions"
                className="tabSection"
                style={{ display: "block", color: "#fff" }}
              >
                <Row>
                  {/* Left Section */}
                  <Col md={9}>
                    <p>All prices displayed in INR</p>

                    <Card
                      className="box plan_renewal_card"
                      style={{ backgroundColor: "#35353587", padding: "20px" }}
                    >
                      <div className="plan_renewal_card__container">
                        <div className="plan_renewal_card__image">
                          <Image
                            src="https://img.freepik.com/premium-photo/cute-cartoon-singer-performing-stage-vector-illustration_1176913-43297.jpg"
                            alt=""
                          />
                        </div>

                        {profile.plan_purchased === null ? (
                          <div className="plan_renewal_card__details_container">
                            <div className="plan_renewal_card__details">
                              <div className="plan_renewal_card__details_left">
                                <h3 className="plan_renewal_card__details_left_header">
                                  {/* {payments &&
                                  payments[0] &&
                                  payments[0].plan_purchased} */}
                                  New Artist
                                </h3>
                                <ListGroup variant="flush">
                                  <ListGroup.Item>
                                    <span className="plan_renewal_card__details_li_label">
                                      Cost:&nbsp;
                                    </span>{" "}
                                    ₹ 0.00
                                    <span
                                      className="vat_tooltip"
                                      data-title="VAT Applicable"
                                    >
                                      <span className="tooltip-info-icon"></span>
                                    </span>
                                  </ListGroup.Item>
                                </ListGroup>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="plan_renewal_card__details_container">
                            <div className="plan_renewal_card__details">
                              <div className="plan_renewal_card__details_left">
                                <h3 className="plan_renewal_card__details_left_header">
                                  {/* {payments &&
                                  payments[0] &&
                                  payments[0].plan_purchased} */}
                                  {profile.plan_purchased}
                                </h3>
                                <ListGroup variant="flush">
                                  <ListGroup.Item>
                                    <span className="plan_renewal_card__details_li_label">
                                      Cost:&nbsp;
                                    </span>{" "}
                                    ₹
                                    {payments &&
                                      payments[0] &&
                                      payments[0].amount}{" "}
                                    {/* <FaInfoCircle /> */}
                                    <span
                                      className="vat_tooltip"
                                      data-title="VAT Applicable"
                                    >
                                      <span className="tooltip-info-icon"></span>
                                    </span>
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <strong>Renewal Due:</strong>{" "}
                                    {new Date(
                                      payments &&
                                        payments[0] &&
                                        new Date(
                                          new Date(
                                            payments[0].created_at
                                          ).setFullYear(
                                            new Date(
                                              payments[0].created_at
                                            ).getFullYear() + 1
                                          )
                                        ).toLocaleDateString()
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })}
                                  </ListGroup.Item>
                                </ListGroup>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Card>
                  </Col>

                  {/* Right Section */}
                  <Col md={3}>
                    <div className="included" style={{ padding: "20px" }}>
                      <h3>You Have Released</h3>
                      <ul className="clearfix">
                        <li>
                          {
                            albums.filter(
                              (album) => album.status === "Released"
                            ).length
                          }{" "}
                          Albums
                        </li>
                        <li>
                          {
                            albums.filter(
                              (album) => album.status !== "Released"
                            ).length
                          }{" "}
                          Pending Releases
                        </li>
                        {/* <li>0 Ringtones</li>
                        <li>0 Videos</li>
                        <li>0 Primary Artists</li> */}
                      </ul>
                    </div>

                    {profile.plan_purchased === "0" && (
                      <aside id="plan-promo">
                        <div className="upgrade-block">
                          <div className="heading">
                            Add Your Releases to{" "}
                            <span className="emphasis">
                              155+ Digital Stores
                            </span>
                          </div>
                          <div className="text-center">
                            <Button
                              className="cta"
                              href="/artist/plans"
                              target="_blank"
                              id="BTN_plan_upgrade_sidebar"
                            >
                              {/* <svg
                            className="record"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1068 1068"
                          >
                            <g>
                              <g>
                                <path
                                  className="record-path"
                                  d="M534,422A112,112,0,1,1,422,534,112,112,0,0,1,534,422m0-115a226.68,226.68,0,1,0,88.39,17.89A225.66,225.66,0,0,0,534,307Z"
                                  fill="#fcda00"
                                ></path>
                                <path
                                  className="record-path"
                                  d="M534,115c231.41,0,419,187.59,419,419S765.41,953,534,953,115,765.41,115,534,302.59,115,534,115M534,0A534.11,534.11,0,0,0,326.11,1026,534.11,534.11,0,0,0,741.89,42,530.7,530.7,0,0,0,534,0Z"
                                  fill="#fcda00"
                                ></path>
                              </g>
                            </g>
                          </svg> */}
                              UPGRADE NOW
                            </Button>
                          </div>
                        </div>
                      </aside>
                    )}
                  </Col>
                </Row>
              </section>
            </Container>
          </Tab>
          <Tab
            eventKey="Order History"
            title="Order History"
            className="order-tab"
          >
            <Container>
              <section
                id="subscriptions"
                className="tabSection"
                style={{ display: "block", color: "#fff" }}
              >
                <Row>
                  {/* Left Section */}
                  <Col md={9}>
                    <p>All amounts in INR.</p>

                    {payments &&
                      payments.map((payment, index) => (
                        <Card className="box">
                          <Card.Body>
                            <div className="summary">
                              <h3>Order {payment.id}</h3>
                              <p>
                                <time dateTime="2024-09-11T00:00:00">
                                  {new Date(
                                    payment.created_at
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}
                                </time>
                              </p>
                              <ul>
                                <li>{payment.plan_purchased}</li>
                              </ul>
                            </div>

                            <div className="details">
                              <p>Total Paid</p>
                              <p className="price">₹{payment.amount}</p>
                            </div>

                            <div className="blue-btn-wrapper">
                              <Button
                                className="blue-btn"
                                variant="primary"
                                // href="/invoices/17011888/download.pdf"
                                // target="_blank"
                                onClick={() => generatePDF(payment)}
                              >
                                View Invoice PDF
                              </Button>
                              <Button
                                className="blue-btn"
                                variant="primary"
                                onClick={() => handleShowDetailsModal(payment)} // Show details modal
                              >
                                View Details
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      ))}
                  </Col>

                  {/* Right Section */}
                  <Col md={3}>
                    <div className="included" style={{ padding: "20px" }}>
                      <h3>You Have Released</h3>
                      <ul className="clearfix">
                        <li>
                          {
                            albums.filter(
                              (album) => album.status === "Released"
                            ).length
                          }{" "}
                          Albums
                        </li>
                        <li>
                          {
                            albums.filter(
                              (album) => album.status !== "Released"
                            ).length
                          }{" "}
                          Pending Releases
                        </li>
                        {/* <li>0 Ringtones</li>
                        <li>0 Videos</li>
                        <li>0 Primary Artists</li> */}
                      </ul>
                    </div>

                    {profile.plan_purchased === "0" && (
                      <aside id="plan-promo">
                        <div className="upgrade-block">
                          <div className="heading">
                            Add Your Releases to{" "}
                            <span className="emphasis">
                              155+ Digital Stores
                            </span>
                          </div>
                          <div className="text-center">
                            <Button
                              className="cta"
                              href="/artist/plans"
                              target="_blank"
                              id="BTN_plan_upgrade_sidebar"
                            >
                              {/* <svg
                            className="record"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1068 1068"
                          >
                            <g>
                              <g>
                                <path
                                  className="record-path"
                                  d="M534,422A112,112,0,1,1,422,534,112,112,0,0,1,534,422m0-115a226.68,226.68,0,1,0,88.39,17.89A225.66,225.66,0,0,0,534,307Z"
                                  fill="#fcda00"
                                ></path>
                                <path
                                  className="record-path"
                                  d="M534,115c231.41,0,419,187.59,419,419S765.41,953,534,953,115,765.41,115,534,302.59,115,534,115M534,0A534.11,534.11,0,0,0,326.11,1026,534.11,534.11,0,0,0,741.89,42,530.7,530.7,0,0,0,534,0Z"
                                  fill="#fcda00"
                                ></path>
                              </g>
                            </g>
                          </svg> */}
                              UPGRADE NOW
                            </Button>
                          </div>
                        </div>
                      </aside>
                    )}
                  </Col>
                </Row>
              </section>
            </Container>
          </Tab>
          {/* <Tab eventKey="contact" title="Contact" disabled>
          Tab content for Contact
        </Tab> */}
        </Tabs>
      </Container>

      {/* Popup Modal */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="popupCtn"
        style={{ zIndex: "9999" }}
        size="lg"
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font26 latoBlack blackText">
            Edit Profile
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div
              className="profilEdtFomCrd__formContanr"
              id="profilEdtFomCrd__formContanr"
            >
              {/* Full Name and Birthday Fields */}
              <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      First Name
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="font14 blackText cap txtFild__textInput"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Last Name
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="font14 blackText cap txtFild__textInput"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="dropDown" id="dob_id">
                    <Form.Label className="font14 latoBold blackText dropDown__label">
                      Birthday
                    </Form.Label>
                    <div className="dropDown__elmntContanr">
                      <Form.Control
                        type="date"
                        className="font14 blackText dropDown__valueBox"
                        name="dob"
                        value={formData.dob}
                        onChange={handleInputChange}
                      ></Form.Control>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Gender and Marital Status Fields */}
              <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="dropDown" id="ed_profile_Gender_id">
                    <Form.Label className="font14 latoBold blackText dropDown__label">
                      Gender
                    </Form.Label>
                    <div className="dropDown__elmntContanr">
                      <Form.Control
                        as="select"
                        name="gender"
                        defaultValue={formData.gender}
                        onChange={handleInputChange}
                        className="font14 blackText dropDown__valueBox"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        {/* <option value="other">Other</option>{" "} */}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="dropDown" id="Marital_Status_PR_id">
                    <Form.Label className="font14 latoBold blackText dropDown__label">
                      Marital Status
                    </Form.Label>
                    <div className="dropDown__elmntContanr">
                      <Form.Control
                        as="select"
                        className="font14 blackText dropDown__valueBox"
                        name="maritalStatus"
                        defaultValue={formData.maritalStatus}
                        onChange={handleInputChange}
                      >
                        <option>Select Status</option>
                        <option value="Married">Married</option>
                        <option value="Single">Single</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Address and Pincode Fields */}
              <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Your Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="font14 blackText cap txtFild__textInput"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Pincode
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="font14 blackText cap txtFild__textInput"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* State Field */}
              <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="dropDown" id="ed_profile_state_id">
                    <Form.Label className="font14 latoBold blackText dropDown__label">
                      State
                    </Form.Label>
                    <div className="dropDown__elmntContanr">
                      <Form.Control
                        as="select"
                        className="font14 blackText dropDown__valueBox"
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                      >
                        <option>Select State</option>
                        <option value="Delhi">Delhi</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
                <Col className="profilEdtFomCrd__fieldColumn">
                  {formData.maritalStatus === "Married" && (
                    <Form.Group className="txtFild">
                      <Form.Label className="font14 latoBold blackText txtFild__label">
                        Anniversary
                      </Form.Label>
                      <Form.Control
                        type="date"
                        className="font14 blackText cap txtFild__textInput"
                        name="anniversaryDate"
                        value={formData.anniversaryDate}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer className="profilEdtFomCrd__footer">
          <span
            className="font16 darkGreyText latoBold cursorPointer"
            onClick={handleCloseModal}
          >
            Cancel
          </span>
          <Button
            variant="primary"
            type="submit"
            className="font16 latoBlack whiteText btn__primry modal_btn"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal2}
        onHide={handleCloseModal2}
        className="popupCtn"
        style={{ zIndex: "9999" }}
        size="lg"
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font26 latoBlack blackText">
            Edit Contact Information
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <div
              className="profilEdtFomCrd__formContanr"
              id="profilEdtFomCrd__formContanr"
            >
              {/* Phone Number Field */}
              {/* <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Phone Number
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="font14 blackText cap txtFild__textInput"
                    />
                  </Form.Group>
                </Col>
              </Row> */}

              {/* Email ID Field */}
              <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Email ID
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      className="font14 blackText cap txtFild__textInput"
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Password Field */}
              {/* <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Password
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="font14 blackText cap txtFild__textInput"
                    />
                  </Form.Group>
                </Col>
              </Row> */}
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer className="profilEdtFomCrd__footer">
          <span
            className="font16 darkGreyText latoBold cursorPointer"
            onClick={handleCloseModal2}
          >
            Cancel
          </span>
          <Button
            variant="primary"
            type="submit"
            className="font16 latoBlack whiteText btn__primry modal_btn"
            onClick={() => {
              handleShowModal3();
              handleCloseModal2();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal3}
        onHide={handleCloseModal3}
        className="popupCtn"
        style={{ zIndex: "9999" }}
        size="lg"
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font26 latoBlack blackText">
            verify Email Address
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <div
              className="profilEdtFomCrd__formContanr"
              id="profilEdtFomCrd__formContanr"
            >
              {/* Phone Number Field */}
              {/* <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Phone Number
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="font14 blackText cap txtFild__textInput"
                    />
                  </Form.Group>
                </Col>
              </Row> */}

              {/* Email ID Field */}
              <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      OTP
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      className="font14 blackText cap txtFild__textInput"
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Password Field */}
              {/* <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Password
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="font14 blackText cap txtFild__textInput"
                    />
                  </Form.Group>
                </Col>
              </Row> */}
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer className="profilEdtFomCrd__footer">
          <span
            className="font16 darkGreyText latoBold cursorPointer"
            onClick={handleCloseModal3}
          >
            Cancel
          </span>
          <Button
            variant="primary"
            type="submit"
            className="font16 latoBlack whiteText btn__primry modal_btn"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal4}
        onHide={handleCloseModal4}
        className="popupCtn"
        style={{ zIndex: "9999" }}
        size="lg"
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font26 latoBlack blackText">
            Change Password
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleotpSubmit}>
            <div
              className="profilEdtFomCrd__formContanr"
              id="profilEdtFomCrd__formContanr"
            >
              {/* Old Password Field */}
              <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Old Password
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        className="font14 blackText cap txtFild__textInput"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                      <span
                        className="position-absolute top-50 translate-middle-y"
                        style={{ right: "10px", cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* New Password Field */}
              <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      New Password
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="newPassword"
                        className="font14 blackText cap txtFild__textInput"
                        value={newPassword}
                        onChange={handlePasswordChange}
                      />
                      <span
                        className="position-absolute top-50 translate-middle-y"
                        style={{ right: "10px", cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Verify New Password Field */}
              <Row className="profilEdtFomCrd__fieldRow">
                <Col className="profilEdtFomCrd__fieldColumn">
                  <Form.Group className="txtFild">
                    <Form.Label className="font14 latoBold blackText txtFild__label">
                      Verify New Password
                      <span className="redText txtFild__lablAstrk">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="verifyNewPassword"
                        className="font14 blackText cap txtFild__textInput"
                        value={verifyNewPassword}
                        onChange={handlePasswordChange}
                      />
                      <span
                        className="position-absolute top-50 translate-middle-y"
                        style={{ right: "10px", cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {!passwordMatch && (
                      <div className="text-danger font14">
                        Passwords do not match!
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer className="profilEdtFomCrd__footer">
          <span
            className="font16 darkGreyText latoBold cursorPointer"
            onClick={handleCloseModal4}
          >
            Cancel
          </span>
          <Button
            variant="primary"
            type="submit"
            className="font16 latoBlack whiteText btn__primry modal_btn"
            disabled={
              !passwordMatch ||
              !oldPassword ||
              !newPassword ||
              !verifyNewPassword
            }
            onClick={handleotpSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal5}
        onHide={handleCloseModal5}
        className="popupCtn"
        style={{ zIndex: "9999" }}
        size="lg"
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font26 latoBlack blackText">
            Edit Bank Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div
              className="profilEdtFomCrd__formContanr"
              id="profilEdtFomCrd__formContanr"
            >
              <Row>
                {/* Bank Account Name Field */}
                <Row
                  className="profilEdtFomCrd__fieldRow"
                  style={{ width: "50%" }}
                >
                  <Col className="profilEdtFomCrd__fieldColumn">
                    <Form.Group className="txtFild">
                      <Form.Label className="font14 latoBold blackText txtFild__label">
                        Bank Account Name
                        <span className="redText txtFild__lablAstrk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="font14 blackText cap txtFild__textInput"
                        // value={formData["bankAccountName"]}
                        // onChange={(e) => setBankAccountName(e.target.value)}
                        // required
                        name="bankAccountName"
                        value={formData.bankAccountName}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Bank Account Number Field */}
                <Row
                  className="profilEdtFomCrd__fieldRow"
                  style={{ width: "50%" }}
                >
                  <Col className="profilEdtFomCrd__fieldColumn">
                    <Form.Group className="txtFild">
                      <Form.Label className="font14 latoBold blackText txtFild__label">
                        Bank Account Number
                        <span className="redText txtFild__lablAstrk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="font14 blackText cap txtFild__textInput"
                        name="bankAccountNumber"
                        value={formData.bankAccountNumber}
                        onChange={handleInputChange}
                        // value={formData["bankAccountNumber"]}
                        // onChange={(e) => setBankAccountNumber(e.target.value)}
                        // required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Bank Name Field */}
                <Row
                  className="profilEdtFomCrd__fieldRow"
                  style={{ width: "50%" }}
                >
                  <Col className="profilEdtFomCrd__fieldColumn">
                    <Form.Group className="txtFild">
                      <Form.Label className="font14 latoBold blackText txtFild__label">
                        Bank Name
                        <span className="redText txtFild__lablAstrk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="font14 blackText cap txtFild__textInput"
                        name="bankName"
                        value={formData.bankName}
                        onChange={handleInputChange}
                        // value={formData["bankName"]}
                        // onChange={(e) => setBankName(e.target.value)}
                        // required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* IFSC Code Field */}
                <Row
                  className="profilEdtFomCrd__fieldRow"
                  style={{ width: "50%" }}
                >
                  <Col className="profilEdtFomCrd__fieldColumn">
                    <Form.Group className="txtFild">
                      <Form.Label className="font14 latoBold blackText txtFild__label">
                        IFSC Code
                        <span className="redText txtFild__lablAstrk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="font14 blackText cap txtFild__textInput"
                        name="ifscCode"
                        value={formData.ifscCode}
                        onChange={handleInputChange}
                        // value={formData["ifscCode"]}
                        // onChange={(e) => setIfscCode(e.target.value)}
                        // required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* SWIFT Code Field */}
                <Row
                  className="profilEdtFomCrd__fieldRow"
                  style={{ width: "50%" }}
                >
                  <Col className="profilEdtFomCrd__fieldColumn">
                    <Form.Group className="txtFild">
                      <Form.Label className="font14 latoBold blackText txtFild__label">
                        SWIFT Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="font14 blackText cap txtFild__textInput"
                        name="swiftCode"
                        value={formData.swiftCode}
                        onChange={handleInputChange}
                        // value={formData["swiftCode"]}
                        // onChange={(e) => setSwiftCode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer className="profilEdtFomCrd__footer">
          <span
            className="font16 darkGreyText latoBold cursorPointer"
            onClick={handleCloseModal5}
          >
            Cancel
          </span>
          <Button
            variant="primary"
            type="submit"
            className="font16 latoBlack whiteText btn__primry modal_btn"
            disabled={
              !formData["bankAccountName"] ||
              !formData["bankAccountNumber"] ||
              !formData["bankName"] ||
              !formData["ifscCode"]
            }
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDetailsModal}
        onHide={handleCloseDetailsModal}
        className="popupCtn"
        style={{ zIndex: "9999" }}
        size="lg"
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font26 latoBlack blackText">
            Payment Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#fff", backgroundColor: "#2C2C2C" }}>
          {selectedPayment && (
            <>
              <div className="payment-details-container">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <p>
                      <strong>Order ID:</strong> {selectedPayment.id}
                    </p>
                    <p>
                      <strong>Razorpay Order ID:</strong>{" "}
                      {selectedPayment.razorpay_order_id}
                    </p>
                    <p>
                      <strong>Razorpay Payment ID:</strong>{" "}
                      {selectedPayment.razorpay_payment_id}
                    </p>
                    <p>
                      <strong>Plan Purchased:</strong>{" "}
                      {selectedPayment.plan_purchased}
                    </p>
                  </div>
                  <div className="col-md-6 mb-3">
                    <p>
                      <strong>Amount:</strong> ₹{selectedPayment.amount}
                    </p>
                    <p>
                      <strong>Status:</strong>{" "}
                      <span
                        className={
                          selectedPayment.status === 1
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {selectedPayment.status === 1 ? "Success" : "Failed"}
                      </span>
                    </p>
                    <p>
                      <strong>Payment Date:</strong>{" "}
                      {new Date(
                        selectedPayment.created_at
                      ).toLocaleDateString()}
                    </p>
                    <p>
                      <strong>Email ID:</strong> {selectedPayment.emailId}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p>
                      <strong>Phone:</strong> {selectedPayment.phone}
                    </p>
                    <p>
                      <strong>Remarks:</strong> {selectedPayment.custom_reason}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="font16 latoBlack whiteText btn__primry modal_btn"
            onClick={handleCloseDetailsModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Profile2;

export const InvoiceTemplate = ({ payment }) => {
  return (
    <div
      className="invoicedata"
      style={{
        // background: "#fff",
        // marginBlock: "100px",
        padding: "80px 100px",
        // width: "2480px",
      }}
    >
      <div>
        <div style={{ display: "flex" }}>
          <p
            style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}
          >
            <img src="/Images/ft-tunes.png" width={300} />
            <br />
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Final Take Production Pvt. Ltd.
            </p>
            {/* <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "117%",
                textAlign: "left",
              }}
            >
              an affiliated company of TuneCore 5 Place de la Gare
            </p> */}
            {/* <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Bureau 601
            </p> */}
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              A2, 2nd Floor, Palam Dabri Road,
              <br /> Mahavir Enclave Dwarka,
              <br /> New Delhi- 110045
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            {/* <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              CRN: B230194
            </p>
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "117%",
                textAlign: "left",
              }}
            >
              VAT Number: LU30940137
            </p> */}
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "117%",
                textAlign: "left",
              }}
            >
              GSTIN: 07AAFCF0284P1Z9
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              <a style={{ color: "#000" }} href="https://www.fttunes.com/">
                www.fttunes.com
              </a>
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "end", width: "50%" }}>
            <div style={{ width: "max-content" }}>
              <p
                className="s1"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Status:{" "}
                <span style={{ color: "#7f7f7f" }}>
                  {payment.status === 1 ? "Success" : "Failed"}
                </span>
              </p>
              <p
                className="s1"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                #INVOICE:{" "}
                <span style={{ color: "#7f7f7f" }}>
                  {payment.razorpay_order_id}
                </span>
              </p>
              <p
                className="s1"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "117%",
                  textAlign: "left",
                }}
              >
                Date:{" "}
                <span style={{ color: "#7f7f7f" }}>
                  {new Date(payment.created_at).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}{" "}
                </span>
              </p>
              <p
                className="s1"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "117%",
                  textAlign: "left",
                }}
              >
                Email:{" "}
                <span style={{ color: "#7f7f7f" }}>{payment.emailId}</span>
              </p>
              <p
                className="s1"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "117%",
                  textAlign: "left",
                }}
              >
                Phone: <span style={{ color: "#7f7f7f" }}>{payment.phone}</span>
              </p>
              {/* <p
                className="s1"
                style={{
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "117%",
                  textAlign: "left",
                }}
              >
                Invoice Address:
                <span style={{ color: "#7f7f7f" }}>
                  Radhey Shyam Park Extn. Delhi
                </span>
              </p>
              <p
                style={{
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Delhi - 7
              </p>
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                110051
              </p>
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                India
              </p> */}
            </div>
          </div>
        </div>

        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <div style={{ display: "flex" }}>
          <table
            style={{
              borderCollapse: "collapse",
              marginLeft: "5.30134pt",
              width: "100%",
            }}
            cellSpacing={0}
          >
            <tbody>
              <tr style={{ height: "40pt", color: "#fff" }}>
                <td
                  style={{
                    width: "191pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "2pt",
                    borderRightColor: "#ffffff",
                  }}
                  bgcolor="#d60d57"
                >
                  <p
                    className="s2"
                    style={{ textIndent: "0pt", textAlign: "center" }}
                  >
                    Product
                  </p>
                </td>
                <td
                  style={{
                    // width: "210pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "2pt",
                    borderLeftColor: "#ffffff",
                    borderRightStyle: "solid",
                    borderRightWidth: "2pt",
                    borderRightColor: "#ffffff",
                  }}
                  colSpan={2}
                  bgcolor="#d60d57"
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "8pt",
                      //   paddingLeft: "64pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    Original Amount
                  </p>
                  <p
                    className="s2"
                    style={{
                      paddingTop: "8pt",
                      //   paddingLeft: "19pt",
                      textIndent: "0pt",
                      textAlign: "center",
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    Amount
                  </p>
                  <p
                    className="s2"
                    style={{
                      paddingTop: "8pt",
                      //   paddingLeft: "19pt",
                      textIndent: "0pt",
                      textAlign: "center",
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    Currency
                  </p>
                </td>
                <td
                  style={{
                    // width: "127pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "2pt",
                    borderLeftColor: "#ffffff",
                  }}
                  bgcolor="#d60d57"
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "8pt",
                      //   paddingLeft: "50pt",
                      //   paddingRight: "3pt",
                      //   textIndent: "-25pt",
                      textAlign: "center",
                    }}
                  >
                    Invoice Amount (INR)
                  </p>
                </td>
              </tr>
              <tr style={{ height: "27pt" }}>
                <td
                  style={{
                    width: "191pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "2pt",
                    borderRightColor: "#ffffff",
                  }}
                  bgcolor="#F0F0F0"
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "7pt",
                      paddingLeft: "12pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    {payment.plan_purchased}
                  </p>
                </td>
                <td
                  style={{
                    width: "78pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "2pt",
                    borderLeftColor: "#ffffff",
                    borderRightStyle: "solid",
                    borderRightWidth: "2pt",
                    borderRightColor: "#ffffff",
                  }}
                  bgcolor="#F0F0F0"
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "7pt",
                      paddingLeft: "11pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    {payment.amount}
                  </p>
                </td>
                <td
                  style={{
                    width: "132pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "2pt",
                    borderLeftColor: "#ffffff",
                    borderRightStyle: "solid",
                    borderRightWidth: "2pt",
                    borderRightColor: "#ffffff",
                  }}
                  bgcolor="#F0F0F0"
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "7pt",
                      paddingLeft: "11pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    INR
                  </p>
                </td>
                <td
                  style={{
                    width: "127pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "2pt",
                    borderLeftColor: "#ffffff",
                  }}
                  bgcolor="#F0F0F0"
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "7pt",
                      paddingRight: "12pt",
                      textIndent: "0pt",
                      textAlign: "right",
                    }}
                  >
                    {payment.amount}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p style={{ paddingTop: "6pt", textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <div style={{ justifyContent: "end", display: "flex" }}>
          <div style={{ width: "50%" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                <tr style={{ height: "27pt" }}>
                  <td style={{ width: "143pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "12pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Base Price
                    </p>
                  </td>
                  <td style={{ width: "79pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "4pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      {((payment.amount * 100) / 118).toFixed(2)}
                    </p>
                  </td>
                  <td style={{ width: "37pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "12pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      INR
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "27pt" }}>
                  <td style={{ width: "143pt" }}>
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "12pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Discounts
                    </p>
                  </td>
                  <td style={{ width: "79pt" }}>
                    <p
                      className="s4"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "4pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      0.00
                    </p>
                  </td>
                  <td style={{ width: "37pt" }}>
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "12pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      INR
                    </p>
                  </td>
                </tr>
                {/* <tr style={{ height: "18pt" }}>
                  <td style={{ width: "259pt" }} colSpan={3} bgcolor="#F0F0F0">
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                </tr> */}
                <tr style={{ height: "46pt" }}>
                  <td style={{ width: "143pt" }}>
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "12pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      GST(18%) <br />
                      {/* Luxembourg */}
                    </p>
                  </td>
                  <td style={{ width: "79pt" }}>
                    <p
                      className="s4"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "4pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      {((payment.amount * 18) / 118).toFixed(2)}
                    </p>
                  </td>
                  <td style={{ width: "37pt" }}>
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "12pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      INR
                    </p>
                  </td>
                </tr>
                {/* <tr style={{ height: "20pt" }}>
                  <td style={{ width: "143pt" }}>
                    <p
                      className="s3"
                      style={{
                        paddingLeft: "12pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Luxembourg
                    </p>
                  </td>
                  <td style={{ width: "79pt" }}>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td style={{ width: "37pt" }}>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                </tr> */}
                {/* <tr style={{ height: "27pt" }}>
                  <td style={{ width: "143pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "12pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Total Incl. Taxes
                    </p>
                  </td>
                  <td style={{ width: "79pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s4"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "4pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      0.00
                    </p>
                  </td>
                  <td style={{ width: "37pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "12pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      INR
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "18pt" }}>
                  <td style={{ width: "143pt" }}>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td style={{ width: "79pt" }}>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td style={{ width: "37pt" }}>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "27pt" }}>
                  <td style={{ width: "143pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "12pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Total payments
                    </p>
                  </td>
                  <td style={{ width: "79pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "4pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      ({payment.amount}
                    </p>
                  </td>
                  <td style={{ width: "37pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s3"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "12pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      INR)
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "18pt" }}>
                  <td style={{ width: "143pt" }}>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td style={{ width: "79pt" }}>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td style={{ width: "37pt" }}>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                </tr> */}
                <tr style={{ height: "27pt" }}>
                  <td style={{ width: "143pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s4"
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "12pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Net payable
                    </p>
                  </td>
                  <td style={{ width: "79pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s4"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "4pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      {payment.amount}
                    </p>
                  </td>
                  <td style={{ width: "37pt" }} bgcolor="#F0F0F0">
                    <p
                      className="s4"
                      style={{
                        paddingTop: "7pt",
                        paddingRight: "12pt",
                        textIndent: "0pt",
                        textAlign: "right",
                      }}
                    >
                      INR
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p style={{ paddingTop: "6pt", textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <div style={{ justifyContent: "end", display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              className="textbox"
              style={{
                background: "#c895a8",
                display: "block",
                minHeight: "49.2pt",
                width: "100%",
                padding: "10px 20px",
              }}
            >
              <p
                className="s5"
                style={{
                  paddingTop: "5pt",
                  //   paddingLeft: "2pt",
                  textIndent: "0pt",
                  lineHeight: "9pt",
                  textAlign: "center",
                }}
              >
                GST (18%)
              </p>
              {/* <p
                className="s5"
                style={{
                  textIndent: "0pt",
                  //   lineHeight: "9pt",
                  textAlign: "center",
                }}
              >
                Immaterial service - Article 56 EU Directive
              </p> */}
              <p
                className="s5"
                style={{
                  paddingTop: "8pt",
                  //   paddingLeft: "2pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                Invoice value is inclusive of 18% GST (HSN code 999611)
              </p>
            </div>
          </div>
        </div>
        <p
          style={{ paddingLeft: "268pt", textIndent: "0pt", textAlign: "left" }}
        ></p>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        {/* <h1 style={{ textIndent: "0pt", textAlign: "center" }}>
          Payment Date:{" "}
          <span className="s1">Payment upon receipt of invoice</span>
        </h1> */}
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <div style={{ display: "flex" }}>
          <div
            className="textbox"
            style={{
              border: "0.6pt solid #d2d2d2",
              display: "block",
              minHeight: "33pt",
              width: "100%",
            }}
          >
            <p
              className="s6"
              style={{
                paddingTop: "6pt",
                // paddingLeft: "225pt",
                // lineHeight: "117%",
                textAlign: "center",
              }}
            >
              FTtunes | Final Take Production Pvt. Ltd. | GSTIN: 07AAFCF0284P1Z9
            </p>
          </div>
        </div>
        <p
          style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
        ></p>
      </div>
    </div>
  );
};
