import React, { useState } from "react";
import {
  Tab,
  Tabs,
  Accordion,
  Form,
  Button,
  Container,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function PaymentOptions() {
  const [activeKey, setActiveKey] = useState("pro-artists");
  const [amount, setAmount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phone, setPhone] = useState("");

  const handleTabSelect = (k) => setActiveKey(k);
  const navigate = useNavigate();

  const handleShowModal = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setAmount(null);
    setShowModal(false);
  };

  const createOrder = async (
    amount,
    artist_id,
    plan_purchased,
    custom_reason,
    emailId,
    phone
  ) => {
    try {
      const response = await axios.post(
        "https://fttunes.com/api/create-order",
        {
          amount: amount,
          artist_id: artist_id,
          plan_purchased: plan_purchased,
          custom_reason: custom_reason,
          emailId: emailId,
          phone: phone,
        }
      );
      if (response.data.success) {
        initiateRazorpayPayment(
          response.data.order_id,
          response.data.amount,
          artist_id,
          plan_purchased,
          custom_reason,
          emailId,
          phone
        );
      } else {
        alert("Failed to create Razorpay order");
      }
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
    }
  };

  const initiateRazorpayPayment = (
    order_id,
    order_amount,
    artist_id,
    plan_purchased,
    custom_reason,
    emailId,
    phone
  ) => {
    const options = {
      key: "rzp_live_wW4Ib28FRtl8gY", // Replace with your Razorpay key_id
      amount: order_amount, // Amount in paise (e.g., 50000 for Rs. 500)
      currency: "INR",
      name: "FINAL TAKE PRODUCTION",
      description: "Buy FTtunes Plan",
      order_id: order_id,
      handler: async (response) => {
        const { razorpay_payment_id, razorpay_signature } = response;
        try {
          const verifyResponse = await axios.post(
            "https://fttunes.com/api/payment/razorpay",
            {
              razorpay_payment_id,
              razorpay_signature,
              razorpay_order_id: order_id,
              amount: order_amount / 100,
              artist_id, // Pass artist_id
              plan_purchased, // Pass plan purchased
              custom_reason, // Pass custom reason
              emailId, // Pass email ID
              phone, // Pass phone number
            }
          );
          if (verifyResponse.data.success) {
            // alert("Payment Successful!");
            navigate(`/payment-success/${order_id}`);
          } else {
            alert("Payment Verification Failed");
          }
        } catch (error) {
          console.error("Error verifying payment:", error);
        }
      },
      prefill: {
        name: "FTTunes User",
        email: emailId,
        contact: phone,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handlePayment = (
    fixedAmount,
    artist_id,
    plan_purchased,
    custom_reason
  ) => {
    if (fixedAmount) {
      createOrder(
        fixedAmount,
        artist_id,
        plan_purchased,
        custom_reason,
        emailId,
        phone
      ); // For Pro Artist and Record Label
    } else {
      if (amount && amount > 0) {
        createOrder(
          amount,
          artist_id,
          plan_purchased,
          custom_reason,
          emailId,
          phone
        ); // For Single Track and Custom Payment
        handleCloseModal();
      } else {
        alert("Please enter a valid amount");
      }
    }
  };

  return (
    <Container>
      <Tabs
        id="client-support-tabs"
        activeKey={activeKey}
        onSelect={handleTabSelect}
        className="mb-3"
        style={{ border: "none" }}
      >
        <Tab eventKey="pro-artists" title="Pro Artists">
          <Container>
            <Row>
              <Col
                md={12}
                className="payer_details"
                style={{
                  marginBottom: "30px",
                  background: "#131313",
                  padding: "20px 40px",
                }}
              >
                <Form
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Group controlId="formEmail" style={{ width: "48%" }}>
                    <Form.Label style={{ color: "#fff" }}>
                      Email address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      style={{ paddingBlock: "15px", color: "grey" }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formPhone" style={{ width: "48%" }}>
                    <Form.Label style={{ color: "#fff" }}>
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter your phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ paddingBlock: "15px", color: "grey" }}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col
                style={{
                  color: "#fff",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* We accept following payment options for your convenience. */}
                <Button
                  className="gradient_border_btn"
                  style={{ float: "right" }}
                  // href="https://rzp.io/i/5mVwQRBAZd"
                  onClick={() =>
                    handlePayment(
                      4999,
                      null,
                      "Pro Artist",
                      "Payment for Pro Artist"
                    )
                  } // Pro Artists payment
                >
                  Pay Now
                </Button>
              </Col>
              <Image
                src="/Images/ft-payment-options.png"
                style={{ marginTop: "20px" }}
              />
            </Row>
          </Container>
        </Tab>
        <Tab eventKey="record-label" title="Record Label">
          <Container>
            <Row>
              <Col
                md={12}
                className="payer_details"
                style={{
                  marginBottom: "30px",
                  background: "#131313",
                  padding: "20px 40px",
                }}
              >
                <Form
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Group controlId="formEmail" style={{ width: "48%" }}>
                    <Form.Label style={{ color: "#fff" }}>
                      Email address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      style={{ paddingBlock: "15px", color: "grey" }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formPhone" style={{ width: "48%" }}>
                    <Form.Label style={{ color: "#fff" }}>
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter your phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ paddingBlock: "15px", color: "grey" }}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col
                style={{
                  color: "#fff",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* We accept following payment options for your convenience. */}
                <Button
                  className="gradient_border_btn"
                  style={{ float: "right" }}
                  // href="https://rzp.io/i/db5ZVYs"
                  onClick={() =>
                    handlePayment(
                      9999,
                      null,
                      "Record Label",
                      "Payment for Record Label"
                    )
                  }
                >
                  Pay Now
                </Button>
              </Col>
              <Image
                src="/Images/ft-payment-options.png"
                style={{ marginTop: "20px" }}
              />
            </Row>
          </Container>
        </Tab>
        <Tab eventKey="single-track" title="Single Track">
          <Container>
            <Row>
              <Col
                md={12}
                className="payer_details"
                style={{
                  marginBottom: "30px",
                  background: "#131313",
                  padding: "20px 40px",
                }}
              >
                <Form
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Group controlId="formEmail" style={{ width: "48%" }}>
                    <Form.Label style={{ color: "#fff" }}>
                      Email address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      style={{ paddingBlock: "15px", color: "grey" }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formPhone" style={{ width: "48%" }}>
                    <Form.Label style={{ color: "#fff" }}>
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter your phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ paddingBlock: "15px", color: "grey" }}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col
                style={{
                  color: "#fff",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* We accept following payment options for your convenience. */}
                <Button
                  className="gradient_border_btn"
                  style={{ float: "right" }}
                  // href="https://rzp.io/l/finaltake"
                  onClick={() => handleShowModal("Single Track Payment")}
                >
                  Pay Now
                </Button>
              </Col>
              <Image
                src="/Images/ft-payment-options.png"
                style={{ marginTop: "20px" }}
              />
            </Row>
          </Container>
        </Tab>
        <Tab eventKey="custom-payment" title="Custom Payment">
          <Container>
            <Row>
              <Col
                md={12}
                className="payer_details"
                style={{
                  marginBottom: "30px",
                  background: "#131313",
                  padding: "20px 40px",
                }}
              >
                <Form
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Group controlId="formEmail" style={{ width: "48%" }}>
                    <Form.Label style={{ color: "#fff" }}>
                      Email address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      style={{ paddingBlock: "15px", color: "grey" }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formPhone" style={{ width: "48%" }}>
                    <Form.Label style={{ color: "#fff" }}>
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter your phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ paddingBlock: "15px", color: "grey" }}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col
                style={{
                  color: "#fff",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* We accept following payment options for your convenience. */}
                <Button
                  className="gradient_border_btn"
                  style={{ float: "right" }}
                  // href="https://rzp.io/l/finaltake"
                  onClick={() => handleShowModal("Custom Payment")}
                >
                  Pay Now
                </Button>
              </Col>
              <Image
                src="/Images/ft-payment-options.png"
                style={{ marginTop: "20px" }}
              />
            </Row>
          </Container>
        </Tab>
      </Tabs>

      {/* Modal for entering amount */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        data-bs-theme="dark"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formAmount">
            <Form.Label style={{ color: "#fff" }}>
              Enter Amount (INR)
            </Form.Label>
            <Form.Control
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              handlePayment(null, null, "Custom Payment", "Custom Payment")
            }
          >
            Pay Now
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default PaymentOptions;
