import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Alert,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import "./NewReleaseForm.css";
import axios from "axios";

const NewReleaseForm = () => {
  const profile = JSON.parse(localStorage.getItem("profile"));
  // const artistName = profile ?
  // profile.firstName
  // // `${profile.firstName}{" "}${profile.lastName}`
  // : "";

  const [formData, setFormData] = useState({
    artistId: profile.artistId,
    // planSelected: "",
    uploadAudio: null,
    uploadPoster: null,
    songName: "",
    // songDuration: "",
    // songLanguage: "",
    mainArtists: [{ name: "" }],
    // singerEmail: profile ? profile.email : "",
    composerName: "",
    lyricistName: "",
    producerName: "",
    songDescription: "",
    // callerTuneName: "",
    // callerTuneStartTime: "",
    primaryGenre: "Indian",
    subGenre: "Hindi",
    secondaryGenre: "Indian",
    secondarySubGenre: "Hindi",
    liveDate: "",
    termsAccepted: false,
    tncAccepted: false,
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to handle button disabling
  const [artistCount, setArtistCount] = useState(0);
  const [isAddArtistDisabled, setIsAddArtistDisabled] = useState(false);

  // File size validation (max 25MB)
  const validateFileSize = (file) => {
    const maxSizeInBytes = 25 * 1024 * 1024; // 25MB in bytes
    return file.size <= maxSizeInBytes;
  };

  // const handleChange = (e) => {
  //   const { name, value, type, checked, files } = e.target;
  //   if (type === "checkbox") {
  //     setFormData({ ...formData, [name]: checked });
  //     console.log("checkbox added");
  //   } else if (type === "file") {
  //     setFormData({ ...formData, [name]: files[0] });
  //     console.log("file added", formData);
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //     console.log("text added");
  //   }

  // };
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    // Log the event to ensure it's triggering
    console.log("Event fired for", name, type);

    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else if (type === "file") {
      if (files.length > 0) {
        setFormData({ ...formData, [name]: files[0] });
        console.log(`${name} file added:`, files[0]); // Check if file is detected
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleArtistChange = (index, e) => {
    const { name, value } = e.target;
    const updatedArtists = [...formData.mainArtists];
    updatedArtists[index][name] = value;
    setFormData({ ...formData, mainArtists: updatedArtists });
  };

  const addArtistField = () => {
    setArtistCount(formData.mainArtists.length); // assuming artist count is stored here or calculated

    // Check plan and disable "Add Artist" button as needed
    if (!profile.plan_purchased || profile.plan_purchased === "0") {
      setIsAddArtistDisabled(true); // Disable if no plan purchased
    } else if (
      profile.plan_purchased === "Pro Artist" &&
      formData.mainArtists.length >= 2
    ) {
      setIsAddArtistDisabled(true); // Disable if "Pro Artist" and already 3 artists
    } else {
      setIsAddArtistDisabled(false); // Allow if plan is "Unlimited Artist" or within limits
    }
    setFormData({
      ...formData,
      mainArtists: [...formData.mainArtists, { name: "" }],
    });
    setArtistCount(formData.mainArtists.length);
  };

  const removeArtistField = (index) => {
    setArtistCount(formData.mainArtists.length); // assuming artist count is stored here or calculated

    // Check plan and disable "Add Artist" button as needed
    if (!profile.plan_purchased || profile.plan_purchased === "0") {
      setIsAddArtistDisabled(true); // Disable if no plan purchased
    } else if (
      profile.plan_purchased === "Pro Artist" &&
      formData.mainArtists.length >= 4
    ) {
      console.log(
        "isaddartistdisabled",
        isAddArtistDisabled,
        profile.plan_purchased,
        formData.mainArtists.length
      );
      setIsAddArtistDisabled(true); // Disable if "Pro Artist" and already 3 artists
    } else {
      setIsAddArtistDisabled(false); // Allow if plan is "Unlimited Artist" or within limits
    }

    if (formData.mainArtists.length > 1) {
      const updatedArtists = formData.mainArtists.filter(
        (artist, i) => i !== index
      );
      setFormData({ ...formData, mainArtists: updatedArtists });
    }
    setArtistCount(formData.mainArtists.length);
    console.log("formdata.mainartist", formData.mainArtists);
  };

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    // Disable the submit button while processing
    setIsSubmitting(true);

    // Validate file size for audio file
    if (formData.uploadAudio && !validateFileSize(formData.uploadAudio)) {
      setError("Audio file size exceeds 25 MB limit.");
      return;
    }

    // Validate file size for poster file
    if (formData.uploadPoster && !validateFileSize(formData.uploadPoster)) {
      setError("Poster file size exceeds 25 MB limit.");
      return;
    }

    // const formDataToSend = new FormData();
    // // formDataToSend.append("planSelected", formData.planSelected);
    // formDataToSend.append("uploadAudio", formData.uploadAudio);
    // formDataToSend.append("uploadPoster", formData.uploadPoster);
    // formDataToSend.append("songName", formData.songName);
    // formDataToSend.append("songDuration", formData.songDuration);
    // formDataToSend.append("songLanguage", formData.songLanguage);
    // formDataToSend.append("mainArtists", formData.mainArtists);
    // // formDataToSend.append("singerEmail", formData.singerEmail);
    // formDataToSend.append("composerName", formData.composerName);
    // formDataToSend.append("lyricistName", formData.lyricistName);
    // formDataToSend.append("producerName", formData.producerName);
    // formDataToSend.append("songDescription", formData.songDescription);
    // // formDataToSend.append("callerTuneName", formData.callerTuneName);
    // // formDataToSend.append("callerTuneStartTime", formData.callerTuneStartTime);
    // formDataToSend.append("liveDate", formData.liveDate);
    // formDataToSend.append("termsAccepted", formData.termsAccepted);
    // formDataToSend.append("tncAccepted", formData.tncAccepted);

    const formDataToSend = formData;
    // formDataToSend.mainArtists = JSON.stringify(formData.mainArtists);
    console.log("form data", formData);
    console.log("form data send", formDataToSend);

    try {
      const response = await axios.post(
        "https://fttunes.com/api/albums",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("response", response);

      if (response.status === 201) {
        setSuccess("Album created successfully!");
        setFormData({
          artistId: profile.artistId,
          // planSelected: "",
          uploadAudio: null,
          uploadPoster: null,
          songName: "",
          // songDuration: "",
          // songLanguage: "",
          mainArtists: [{ name: "" }],
          // singerEmail: profile ? profile.email : "",
          composerName: "",
          lyricistName: "",
          producerName: "",
          songDescription: "",
          // callerTuneName: "",
          // callerTuneStartTime: "",
          primaryGenre: "Indian",
          subGenre: "Hindi",
          secondaryGenre: "Indian",
          secondarySubGenre: "Hindi",
          liveDate: "",
          termsAccepted: false,
          tncAccepted: false,
        });
        setShowModal(true);

        // Navigate after 3 seconds
        setTimeout(() => {
          navigate("/artist/dashboard");
        }, 3000);
      } else {
        setError("Failed to create album.");
      }
    } catch (err) {
      setError("An error occurred while creating the album.");
      console.log(err);
    } finally {
      setIsSubmitting(false); // Re-enable the submit button
    }
  };

  const primaryGenres = [
    { value: "40", label: "Indian" },
    { value: "125", label: "African" },
    { value: "1", label: "Alternative" },
    { value: "101", label: "Ambient" },
    { value: "28", label: "Americana" },
    { value: "35", label: "Big Band" },
    { value: "3", label: "Blues" },
    { value: "102", label: "Brazilian" },
    { value: "4", label: "Children's Music" },
    { value: "15", label: "Christian/Gospel" },
    { value: "164", label: "Classical Crossover" },
    { value: "6", label: "Comedy" },
    { value: "29", label: "Country" },
    { value: "7", label: "Dance" },
    { value: "8", label: "Electronic" },
    { value: "36", label: "Fitness & Workout" },
    { value: "9", label: "Folk" },
    { value: "10", label: "French Pop" },
    { value: "11", label: "German Folk" },
    { value: "12", label: "German Pop" },
    { value: "31", label: "Heavy Metal" },
    { value: "13", label: "Hip Hop/Rap" },
    { value: "14", label: "Holiday" },
    // { value: "40", label: "Indian" },
    { value: "38", label: "Instrumental" },
    { value: "32", label: "J Pop" },
    { value: "16", label: "Jazz" },
    { value: "33", label: "K Pop" },
    { value: "39", label: "Karaoke" },
    { value: "17", label: "Latin" },
    { value: "18", label: "New Age" },
    { value: "20", label: "Pop" },
    { value: "21", label: "R&B/Soul" },
    { value: "22", label: "Reggae" },
    { value: "23", label: "Rock" },
    { value: "34", label: "Singer/Songwriter" },
    { value: "24", label: "Soundtrack" },
    { value: "30", label: "Spoken Word" },
    { value: "26", label: "Vocal" },
    { value: "27", label: "World" },
  ];

  const primarySubGenres = [
    { value: "65", label: "Hindi" },
    { value: "41", label: "Assamese" },
    { value: "43", label: "Bengali" },
    { value: "45", label: "Bhangra" },
    { value: "46", label: "Bhojpuri" },
    { value: "48", label: "Bollywood" },
    { value: "51", label: "Children Song" },
    { value: "55", label: "Devotional & Spiritual" },
    { value: "56", label: "Dialogue" },
    { value: "57", label: "DJ" },
    { value: "59", label: "Fusion" },
    { value: "60", label: "Gazal" },
    { value: "61", label: "Gujarati" },
    { value: "63", label: "Haryanvi" },
    // { value: "65", label: "Hindi" },
    { value: "58", label: "Indian - Folk" },
    { value: "70", label: "Indigenous" },
    { value: "71", label: "Kannada" },
    { value: "73", label: "Konkani" },
    { value: "74", label: "Malayalam" },
    { value: "76", label: "Mappila" },
    { value: "77", label: "Marathi" },
    { value: "79", label: "Odia" },
    { value: "81", label: "Poetry" },
    { value: "82", label: "Pop & Fusion" },
    { value: "83", label: "Punjabi" },
    { value: "85", label: "Rabindra Sangeet" },
    { value: "86", label: "Rajasthani" },
    { value: "88", label: "Regional Indian" },
    { value: "90", label: "Sanskrit" },
    { value: "92", label: "Speech" },
    { value: "93", label: "Sufi" },
    { value: "94", label: "Tamil" },
    { value: "96", label: "Telugu" },
    { value: "98", label: "Traditional" },
    { value: "99", label: "Urdu" },
  ];

  const secondaryGenres = primaryGenres;
  const secondarySubGenres = primarySubGenres;

  return (
    <Container className="dark-theme-container">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card
            // style={{background:"#000"}}
            className="dark-theme-card"
          >
            <Card.Body>
              {/* <Card.Title className="text-center">Upload Your Song</Card.Title> */}
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form onSubmit={handleSubmit}>
                {/* <Form.Group controlId="formPlanSelected">
                  <Form.Label>Plan Selected</Form.Label>
                  <Form.Control
                    as="select"
                    name="planSelected"
                    value={formData.planSelected}
                    onChange={handleChange}
                    required
                  >
                    <option value="">—Please choose an option—</option>
                    <option value="Low Plan">Low Plan</option>
                    <option value="Mid Plan">Mid Plan</option>
                    <option value="High Plan 1">High Plan 1</option>
                    <option value="High Plan 2">High Plan 2</option>
                  </Form.Control>
                </Form.Group> */}

                <Row>
                  <Form.Group
                    controlId="formUploadAudio"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>
                      Select Audio file to upload (25 MB Max)
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="uploadAudio"
                      accept=".wav,.mp3"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formUploadPoster"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>
                      Select Poster/Artwork file to Upload
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="uploadPoster"
                      accept=".png,.jpeg,.jpg,.pdf,.docx"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row>

                <Form.Group controlId="formSongName">
                  <Form.Label>Song Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="songName"
                    value={formData.songName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                {/* <Row>
                  <Form.Group
                    controlId="formSongDuration"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>Song length/duration (hh:mm:ss)</Form.Label>
                    <Form.Control
                      type="text"
                      name="songDuration"
                      value={formData.songDuration}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formSongLanguage"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>Language of the song</Form.Label>
                    <Form.Control
                      type="text"
                      name="songLanguage"
                      value={formData.songLanguage}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row> */}

                <Row>
                  {formData.mainArtists.map((artist, index) => (
                    <Form.Group
                      controlId="formSingerName"
                      style={{ width: "50%", position: "relative" }}
                    >
                      <Form.Label>Singer/Artists name(s)</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={artist.name}
                        onChange={(e) => handleArtistChange(index, e)}
                        required
                      />
                      <Button
                        variant="link"
                        onClick={() => removeArtistField(index)}
                        disabled={formData.mainArtists.length === 1}
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          right: "15px",
                          textDecoration: "none",
                        }}
                      >
                        X
                      </Button>
                    </Form.Group>
                  ))}
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                      margin: "10px 0px 20px",
                    }}
                  >
                    <Button
                      className="add_release_btn"
                      style={{ width: "25%" }}
                      onClick={addArtistField}
                      disabled={isAddArtistDisabled}
                    >
                      Add Another Artist
                      {isAddArtistDisabled && (
                        <p style={{ color: "grey" }}>
                          (Cannot add more artist)
                        </p>
                      )}
                    </Button>
                  </div>

                  {/* <Form.Group
                    controlId="formSingerEmail"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>Singer/Artists mail</Form.Label>
                    <Form.Control
                      type="email"
                      name="singerEmail"
                      value={formData.singerEmail}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group> */}
                </Row>

                <Row>
                  <Form.Group
                    controlId="formComposerName"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>Composer/Music</Form.Label>
                    <Form.Control
                      type="text"
                      name="composerName"
                      value={formData.composerName}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formLyricistName"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>Lyricist</Form.Label>
                    <Form.Control
                      type="text"
                      name="lyricistName"
                      value={formData.lyricistName}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row>

                <Form.Group
                  controlId="formProducerName"
                  style={{ width: "50%" }}
                >
                  <Form.Label>Music Producer (optional)</Form.Label>
                  <Form.Control
                    type="text"
                    name="producerName"
                    value={formData.producerName}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Row>
                  <Form.Group
                    controlId="formPlanSelected"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>Primary Genre</Form.Label>
                    <Form.Control
                      as="select"
                      name="primaryGenre"
                      value={formData.primaryGenre}
                      onChange={handleChange}
                      defaultValue="Indian"
                      required
                    >
                      {primaryGenres.map((genre) => (
                        <option key={genre.value} value={genre.label}>
                          {genre.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="formPlanSelected"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>Sub Genre</Form.Label>
                    <Form.Control
                      as="select"
                      name="subGenre"
                      value={formData.subGenre}
                      onChange={handleChange}
                      defaultValue="Hindi"
                      required
                    >
                      {primarySubGenres.map((subGenre) => (
                        <option key={subGenre.value} value={subGenre.label}>
                          {subGenre.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="formPlanSelected"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>Secondary Genre</Form.Label>
                    <Form.Control
                      as="select"
                      name="secondaryGenre"
                      value={formData.secondaryGenre}
                      onChange={handleChange}
                      defaultValue="Indian"
                      required
                    >
                      {secondaryGenres.map((genre) => (
                        <option key={genre.value} value={genre.label}>
                          {genre.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="formPlanSelected"
                    style={{ width: "50%" }}
                  >
                    <Form.Label>Sub Genre</Form.Label>
                    <Form.Control
                      as="select"
                      name="secondarySubGenre"
                      value={formData.secondarySubGenre}
                      onChange={handleChange}
                      defaultValue="Hindi"
                      required
                    >
                      {secondarySubGenres.map((subGenre) => (
                        <option key={subGenre.value} value={subGenre.label}>
                          {subGenre.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Row>

                <Form.Group controlId="formSongDescription">
                  <Form.Label>One-Line description about your song</Form.Label>
                  <Form.Control
                    type="text"
                    name="songDescription"
                    value={formData.songDescription}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Row>
                  {/* <Form.Group
                    controlId="formCallerTuneName"
                    style={{ width: "33%" }}
                  >
                    <Form.Label>
                      CallerTune Name (it can be same as song name)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="callerTuneName"
                      value={formData.callerTuneName}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formCallerTuneStartTime"
                    style={{ width: "33%" }}
                  >
                    <Form.Label>
                      CallerTune start timing in (hh:mm:ss)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="callerTuneStartTime"
                      value={formData.callerTuneStartTime}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group> */}

                  <Form.Group controlId="formLiveDate" style={{ width: "33%" }}>
                    <Form.Label>
                      Date you want your song to be LIVE on
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="liveDate"
                      min="2024-01-01"
                      value={formData.liveDate}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row>

                <Form.Group controlId="formTermsAndConditions">
                  <Form.Check
                    type="checkbox"
                    name="termsAccepted"
                    label="I have full rights to upload and monetize the uploaded song or audio through FTtunes. The song uploaded by me is original in composition, lyrics, and music, and I own the copyright. This is not a cover song. In case of any third-party claim, I have documentary evidence to prove my ownership of the song. The copyright belongs to me, and I am not transferring any copyright to FTtunes; FTtunes is only distributing and monetizing my song on my behalf worldwide."
                    checked={formData.termsAccepted}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formTNC">
                  <Form.Check
                    type="checkbox"
                    name="tncAccepted"
                    label="I have read and agree to the website Terms and Conditions"
                    checked={formData.tncAccepted}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  block
                  disabled={isSubmitting} // Disable when submitting
                  className="add_release_btn"
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Success Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        backdrop="static"
        keyboard={false}
        className="success-modal"
      >
        <Modal.Body className="text-center">
          <FaCheckCircle className="success-icon" />
          <h4 className="mt-3">Success!</h4>
          <p>Your release request has been submitted successfully.</p>
          <p>You will be redirected to your dashboard shortly.</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            onClick={() => navigate("/artist/dashboard")}
            className="px-4"
          >
            Go to Dashboard Now
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default NewReleaseForm;
