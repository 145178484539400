import React, { useEffect, useState } from "react";
import { Card, Table, Form, Button, Col, Modal, Row } from "react-bootstrap";
import { FaCircle, FaDownload, FaEye, FaTrash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const AllReportsTable = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const artistProfile = JSON.parse(localStorage.getItem("profile"));
        const artistId = artistProfile?.artistId;

        if (!artistId) {
          setError("Artist ID not found");
          return;
        }
        console.log("artist Idddddd", artistId);
        const response = await axios.get(
          `https://fttunes.com/api/reports/${artistId}`
        );
        setReports(response.data.reports); // Adjust based on your API response structure
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch reports");
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  //   const handleDeleteReport = async (id) => {
  //     if (window.confirm("Are you sure you want to delete this report?")) {
  //       try {
  //         await axios.delete(`https://fttunes.com/api/reports/${id}`); // Adjust API endpoint
  //         setReports(reports.filter((report) => report.id !== id));
  //       } catch (error) {
  //         console.error("Error deleting report:", error);
  //       }
  //     }
  //   };
  const [selectedReport, setSelectedReport] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleViewDetails = (report) => {
    setSelectedReport(report);
    setShowModal(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Col className="artists-table">
      <Card>
        <Card.Header>
          <h4 className="card-title">
            <mark>My Reports</mark>
          </h4>
          {/* <Button
            as={Link}
            to="/artist/reports/create"
            className="theme-btn add_report_btn"
          >
            Add New Report
          </Button> */}
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table responsive="md" variant="dark">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>
                    <Form.Check
                      type="checkbox"
                      id="checkAllReports"
                      className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                      style={{ border: "none" }}
                      label=""
                    />
                  </th>
                  <th>ID</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Remarks</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reports.length>0 ? (
                  reports.map((report, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          id={`customCheckBoxReport${index + 2}`}
                          className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                          label=""
                          style={{ border: "none" }}
                        />
                      </td>
                      <td>
                        <strong>{report.id}</strong>
                      </td>
                      <td>{new Date(report.startTime).toLocaleDateString()}</td>
                      <td>{new Date(report.endTime).toLocaleDateString()}</td>
                      <td>{report.remarks}</td>
                      <td>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleViewDetails(report)}
                        >
                          <FaEye /> View
                        </Button>
                        <Button
                          variant="success"
                          href={`https://fttunes.com/api/${report.filePath}`}
                          target="_blank"
                          size="sm"
                          // onClick={() =>
                          //   navigate(`https://fttunes.com/${report.filePath}`)
                          // }
                          style={{ marginLeft: "5px" }} // Optional: adds space between buttons
                        >
                          <FaDownload /> Download
                        </Button>
                      </td>
                    </tr>
                  ))) : (
                    <tr>
                      <td colSpan="5">No report to show.</td>
                    </tr>
                  )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      {/* Modal to show report details */}
      {selectedReport && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          centered
          className="change_status_modal"
        >
          <Modal.Header closeButton style={{backgroundColor:"#76009f"}}>
            <Modal.Title style={{ color: "#fff" }}>
              Report Details - {selectedReport.reportId}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ color: "#fff" }}>
              <Col md={6}>
                <h5>Report Information</h5>
                <p>
                  <strong>Start Date:</strong>{" "}
                  {new Date(selectedReport.startTime).toLocaleDateString()}
                </p>
                <p>
                  <strong>End Date:</strong>{" "}
                  {new Date(selectedReport.endTime).toLocaleDateString()}
                </p>
                <p>
                  <strong>Remarks:</strong> {selectedReport.remarks}
                </p>
              </Col>
              <Col md={6}>
                <h5>File</h5>
                <p>
                  <strong>Report File:</strong>{" "}
                  <a
                    href={selectedReport.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Report
                  </a>
                </p>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Col>
  );
};

export default AllReportsTable;
